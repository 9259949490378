import React from 'react';
import { useApp } from '../context/AppContext';

const Footer = () => {
  const { theme } = useApp();
  const currentYear = new Date().getFullYear();

  const footerLinks = {
    company: [
      { label: 'About Us', href: '/about' },
      { label: 'Contact', href: '/contact' },
      { label: 'Careers', href: '/careers' },
    ],
    resources: [
      { label: 'Blog', href: '/blog' },
      { label: 'Documentation', href: '/documentation' },
      { label: 'Support', href: '/support' },
    ],
    legal: [
      { label: 'Privacy Policy', href: '/privacy' },
      { label: 'Terms of Service', href: '/terms' },
      { label: 'Cookie Policy', href: '/cookies' },
    ],
    social: [
      { label: 'Twitter', href: 'https://twitter.com/amafreiburg', icon: 'fab fa-twitter' },
      { label: 'LinkedIn', href: 'https://linkedin.com/company/amafreiburg', icon: 'fab fa-linkedin' },
      { label: 'GitHub', href: 'https://github.com/amafreiburg', icon: 'fab fa-github' },
    ]
  };

  const FooterSection = ({ title, links, isSocial = false }) => (
    <div>
      <h3 className="font-semibold mb-4 text-gray-900 dark:text-white">{title}</h3>
      {isSocial ? (
        <div className="flex space-x-4 mb-4">
          {links.map((link) => (
            <a
              key={link.label}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white transition-colors duration-200"
              aria-label={link.label}
            >
              <i className={link.icon}></i>
            </a>
          ))}
        </div>
      ) : (
        <ul className="space-y-2">
          {links.map((link) => (
            <li key={link.label}>
              <a
                href={link.href}
                className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white transition-colors duration-200"
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <footer className="bg-gray-100 dark:bg-gray-900 py-8 mt-auto transition-colors duration-200">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <FooterSection title="Company" links={footerLinks.company} />
          <FooterSection title="Resources" links={footerLinks.resources} />
          <FooterSection title="Legal" links={footerLinks.legal} />
          
          <div>
            <FooterSection title="Connect With Us" links={footerLinks.social} isSocial={true} />
            <p className="text-sm mt-4">
              <a 
                href="mailto:contact@amafreiburg.de" 
                className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white transition-colors duration-200"
              >
                contact@ama-freiburg.org
              </a>
            </p>
          </div>
        </div>

        <div className="border-t border-gray-200 dark:border-gray-800 mt-8 pt-8 text-sm text-center">
          <p className="text-gray-600 dark:text-gray-400">&copy; {currentYear} AMA Freiburg. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 