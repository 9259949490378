import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import { useApp } from '../context/AppContext';

const BasePage = ({ children }) => {
  const { theme } = useApp();

  return (
    <div className={`min-h-screen flex flex-col ${theme === 'dark' ? 'dark' : ''}`}>
      <NavBar />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default BasePage; 