import './App.css';
import BasePage from './components/BasePage';
import Slider from './components/Slider';
import { AppProvider } from './context/AppContext';

function App() {
  return (
    <AppProvider>
      <BasePage>
        <div className="flex flex-col items-center justify-center space-y-4">
          <h1 className="text-4xl font-bold">Welcome to AMA Freiburg</h1>
          <p className="text-lg text-muted-foreground">
            Making a difference in our community
          </p>
        </div>
        <Slider />
      </BasePage>
    </AppProvider>
  );
}

export default App;
