import React, { useState, useEffect } from 'react';

const Slider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const slides = [
        {
            title: "Who are we?",
            content: (
                <div className="space-y-6 text-center">
                    <p className="text-3xl font-medium text-foreground">
                        WE ARE <span className="text-rose-600/80 dark:text-rose-400 font-bold">ANTI-FASCISTS</span>
                    </p>
                    <p className="text-3xl font-medium text-foreground">
                        WE ARE <span className="text-emerald-600/80 dark:text-emerald-400 font-bold">PRO-MIGRANTS</span>
                    </p>
                </div>
            )
        },
        {
            title: "What we do?",
            content: (
                <div className="space-y-4 text-center">
                    <p className="text-3xl font-medium text-foreground">
                        WE <span className="text-slate-600/90 dark:text-slate-300 font-bold">TALK</span>
                    </p>
                    <p className="text-3xl font-medium text-foreground">
                        WE <span className="text-slate-600/90 dark:text-slate-300 font-bold">THINK</span>
                    </p>
                    <p className="text-3xl font-medium text-foreground">
                        WE <span className="text-slate-600/90 dark:text-slate-300 font-bold">HELP</span>
                    </p>
                    <div className="mt-8 space-y-2">
                        <p className="text-2xl font-medium text-foreground">
                            Let's build a strong migrant community together
                        </p>
                        <p className="text-xl text-muted-foreground">
                            Join us at our events
                        </p>
                    </div>
                </div>
            )
        },
        {
            title: "Our Events",
            content: (
                <div className="text-3xl font-medium text-foreground text-center">
                    Sample content for Our Events
                </div>
            )
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => 
                prevIndex === slides.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="w-full max-w-4xl mx-auto py-12">
            {/* Navigation Buttons */}
            <div className="flex justify-center gap-4 mb-12">
                {slides.map((slide, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`
                            px-6 py-2 rounded-sm text-sm font-medium
                            transition-colors duration-300
                            ${currentIndex === index 
                                ? 'bg-background text-foreground border border-foreground'
                                : 'text-muted-foreground hover:text-foreground'
                            }
                        `}
                    >
                        {slide.title}
                    </button>
                ))}
            </div>

            {/* Slider Content */}
            <div className="min-h-[300px] relative">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`
                            absolute w-full transition-all duration-500
                            ${currentIndex === index 
                                ? 'opacity-100 translate-x-0'
                                : 'opacity-0 translate-x-8'
                            }
                        `}
                    >
                        {slide.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Slider; 